import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

const initialState = {
   isLoading: false,
   error: null,
   affiliates: [],
   affiliate: null,
};

const slice = createSlice({
   name: 'affiliate',
   initialState,
   reducers: {
      // START LOADING
      startLoading(state) {
         state.isLoading = true;
      },

      // HAS ERROR
      hasError(state, action) {
         state.isLoading = false;
         state.error = action.payload;
      },

      getAffiliateSuccess(state, action) {
         state.isLoading = false;
         state.affiliate = action.payload;
      },

      getAffiliatesSuccess(state, action) {
         state.isLoading = false;
         state.affiliates = action.payload;
      },
   },
});

// Reducers
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getAllAffiliates(filters = {}) {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get('/api/users/affiliates', {
            params: {
               filters
            },
         });
         dispatch(slice.actions.getAffiliatesSuccess(response.data));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

export function getAffiliateById(id) {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get(`/api/users/affiliate/${id}`);
         dispatch(slice.actions.getUserSuccess(response.data));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}
