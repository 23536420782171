import PropTypes from "prop-types";
import { Quill } from "react-quill";
// components
import Iconify from "../Iconify";
import EditorToolbarStyle from "./EditorToolbarStyle";
// utils
import axios from "../../utils/axios";
// ----------------------------------------------------------------------

const FONT_FAMILY = [
  "Arial",
  "Tahoma",
  "Georgia",
  "Impact",
  "Verdana",
];

const FONT_SIZE = [
  "8px",
  "9px",
  "10px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];
const HEADINGS = [
  "Heading 1",
  "Heading 2",
  "Heading 3",
  "Heading 4",
  "Heading 5",
  "Heading 6",
];

export function undoChange() {
  this.quill.history.undo();
}
export function redoChange() {
  this.quill.history.redo();
}

const Size = Quill.import("attributors/style/size");
Size.whitelist = FONT_SIZE;
Quill.register(Size, true);

const Font = Quill.import("attributors/style/font");
Font.whitelist = FONT_FAMILY;
Quill.register(Font, true);

export const formats = [
  "align",
  "background",
  "blockquote",
  "bold",
  "bullet",
  "code",
  "code-block",
  "color",
  "direction",
  "font",
  "formula",
  "header",
  "image",
  "indent",
  "italic",
  "link",
  "list",
  "script",
  "size",
  "strike",
  "table",
  "underline",
  "video",
];

// Função de upload do PDF e inserção de link
async function handlePDFUpload(event) {
  const file = event.target.files[0];
  if (file) {
    const formData = new FormData();
    formData.append("files", file);

    try {
      // Faz o upload do arquivo para o servidor
      const response = await axios.post(
        "/api/upload/pdf",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { url } = response.data;

      // prompt to get name of button
      // eslint-disable-next-line no-alert
      const caption = prompt(
        "Digite o nome do botão de download do PDF"
      );

      // force copy to clipboard
      const dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.setAttribute("value", url);
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      // // Obtém a posição atual do cursor no editor
      // const range = quillInstance.getSelection();

      // // Insere o link no editor com o nome do arquivo
      // if (range) {
      //   quillInstance.insertText(range.index, file.name, 'link', url, 'user');
      //   quillInstance.setSelection(range.index + file.name.length);
      // }

      // put the end of text a button with the link to download and caption "Download PDF"

      const quill = document.querySelector(".ql-editor");
      const link = document.createElement("a");

      // get a outlined border with color primary.main var
      // link.style.border = "1px solid #eee";
      // link.style.borderRadius = "4px";
      // link.style.padding = "4px 8px";
      link.style.margin = "8px";
      link.style.textDecoration = "none";
      // link.style.color = "var(--primary)";
      link.style.fontWeight = "bold";
      link.style.cursor = "pointer";
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
      link.setAttribute("download", file.name);
      link.setAttribute(
        "title",
        "Clique para baixar o PDF"
      );
      link.textContent = caption || "Download PDF";

      quill.appendChild(link);
    } catch (error) {
      console.error("Erro no upload do PDF:", error);
    }
  }
}

EditorToolbar.propTypes = {
  id: PropTypes.string.isRequired,
  isSimple: PropTypes.bool,
};

export default function EditorToolbar({
  id,
  isSimple,
  ...other
}) {
  return (
    <EditorToolbarStyle {...other}>
      <div id={id}>
        <div className="ql-formats">
          {!isSimple && (
            <select className="ql-font" defaultValue="">
              <option value="">Font</option>
              {FONT_FAMILY.map((font) => (
                <option key={font} value={font}>
                  {font}
                </option>
              ))}
            </select>
          )}

          {!isSimple && (
            <select className="ql-size" defaultValue="16px">
              {FONT_SIZE.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          )}

          <select className="ql-header" defaultValue="">
            {HEADINGS.map((heading, index) => (
              <option key={heading} value={index + 1}>
                {heading}
              </option>
            ))}
            <option value="">Normal</option>
          </select>
        </div>

        <div className="ql-formats">
          <button type="button" className="ql-bold" />
          <button type="button" className="ql-italic" />
          <button type="button" className="ql-underline" />
          <button type="button" className="ql-strike" />
        </div>

        {!isSimple && (
          <div className="ql-formats">
            <select className="ql-color" />
            <select className="ql-background" />
          </div>
        )}

        <div className="ql-formats">
          <button
            type="button"
            className="ql-list"
            value="ordered"
          />
          <button
            type="button"
            className="ql-list"
            value="bullet"
          />
          {!isSimple && (
            <button
              type="button"
              className="ql-indent"
              value="-1"
            />
          )}
          {!isSimple && (
            <button
              type="button"
              className="ql-indent"
              value="+1"
            />
          )}
        </div>

        {!isSimple && (
          <div className="ql-formats">
            <button
              type="button"
              className="ql-script"
              value="super"
            />
            <button
              type="button"
              className="ql-script"
              value="sub"
            />
          </div>
        )}

        {!isSimple && (
          <div className="ql-formats">
            <button
              type="button"
              className="ql-code-block"
            />
            <button
              type="button"
              className="ql-blockquote"
            />
          </div>
        )}

        <div className="ql-formats">
          <button
            type="button"
            className="ql-direction"
            value="rtl"
          />
          <select className="ql-align" />
        </div>

        <div className="ql-formats">
          <button type="button" className="ql-link" />
          <button type="button" className="ql-image" />
          <button type="button" className="ql-video" />
          {/* Novo botão para upload de PDF */}
          <div className="ql-formats">
            <input
              type="file"
              accept="application/pdf"
              id="pdf-upload"
              style={{ display: "none" }}
              onChange={(event) => handlePDFUpload(event)}
            />
            <button
              type="button"
              onClick={() =>
                document
                  .getElementById("pdf-upload")
                  .click()
              }
            >
              <Iconify
                icon={"ic:round-picture-as-pdf"}
                width={16}
                height={16}
              />
            </button>
          </div>
        </div>

        <div className="ql-formats">
          {!isSimple && (
            <button type="button" className="ql-formula" />
          )}
          <button type="button" className="ql-clean" />
        </div>

        {!isSimple && (
          <div className="ql-formats">
            <button type="button" className="ql-undo">
              <Iconify
                icon={"ic:round-undo"}
                width={18}
                height={18}
              />
            </button>
            <button type="button" className="ql-redo">
              <Iconify
                icon={"ic:round-redo"}
                width={18}
                height={18}
              />
            </button>
          </div>
        )}
      </div>
    </EditorToolbarStyle>
  );
}
