import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField } from '@mui/material';
// components
import { phoneNumberMask } from '../../../../utils/masks';
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

AffiliateTableToolbar.propTypes = {
   filters: PropTypes.object,
   onFilterName: PropTypes.func,
   onFilterCpf: PropTypes.func,
   onFilterPhone: PropTypes.func,
   onFilterAffiliateCode: PropTypes.func,
};

export default function AffiliateTableToolbar({
   filters,
   onFilterName,
   onFilterCpf,
   onFilterPhone,
   onFilterAffiliateCode
}) {
   return (
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
         <TextField
            fullWidth
            value={filters.name}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder="Nome"
            InputProps={{
               startAdornment: (
                  <InputAdornment position="start">
                     <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
               ),
            }}
         />

         <TextField
            fullWidth
            value={filters.cpf}
            onChange={(event) => onFilterCpf(event.target.value)}
            placeholder="CPF"
            InputProps={{
               startAdornment: (
                  <InputAdornment position="start">
                     <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
               ),
            }}
         />

         <TextField
            fullWidth
            value={filters.phone}
            onChange={(event) => onFilterPhone(phoneNumberMask(event.target.value))}
            placeholder="Telefone"
            InputProps={{
               startAdornment: (
                  <InputAdornment position="start">
                     <Iconify icon={'eva:phone-call-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
               ),
            }}
         />

         <TextField
            fullWidth
            value={filters.affiliateCode}
            onChange={(event) => onFilterAffiliateCode(event.target.value)}
            placeholder="Afiliado"
            InputProps={{
               startAdornment: (
                  <InputAdornment position="start">
                     <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
               ),
            }}
         />

      </Stack>
   );
}
