import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem, Stack } from '@mui/material';
// components
import { useSnackbar } from 'notistack';
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
// utils
import createAvatar from '../../../../utils/createAvatar';
import axios from '../../../../utils/axios';
import { fNumberDecimals, fNumberReal } from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------

AffiliateTableRow.propTypes = {
   row: PropTypes.object,
   selected: PropTypes.bool,
   onEditRow: PropTypes.func,
   onSelectRow: PropTypes.func,
};

export default function AffiliateTableRow({ row, selected, onEditRow }) {
   const theme = useTheme();

   const { avatar, name, phone, cpf, isActive, photoUrl, affiliateCode, totalPaidAmount, totalPaidQuantity } = row;

   const [user, setUser] = useState(row);

   const [openMenu, setOpenMenuActions] = useState(null);

   const { enqueueSnackbar } = useSnackbar()

   const handleOpenMenu = (event) => {
      setOpenMenuActions(event.currentTarget);
   };

   const handleCloseMenu = () => {
      setOpenMenuActions(null);
   };

   const handleGenerateAffiliateCode = async (user) => {
      try {
         // generate code and update variables user
         const { data } = await axios.post(`/api/user/${user._id}/affiliate/create-code`);
         console.log('data', data)
         // update user
         setUser((prev) => ({
            ...prev,
            affiliateCode: data
         }))

      } catch (error) {
         console.error(error);
         enqueueSnackbar('Erro ao gerar código de afiliado', { variant: 'error' });
      };
   }

   return (
      <TableRow hover selected={selected}>
         {/* <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
         </TableCell> */}

         <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
            {avatar && (
               <Avatar alt={name} src={avatar} sx={{ mr: 2 }} />
            )}
            {!avatar && (
               <Avatar alt={name} src={photoUrl} sx={{ mr: 2 }} color={photoUrl ? 'default' : createAvatar(name).color}>
                  {createAvatar(name).name}
               </Avatar>
            )}
            <Typography variant="subtitle2" noWrap>
               {name}
            </Typography>
         </TableCell>

         <TableCell align="left" sx={{ textWrap: 'nowrap' }}>
            {phone}
         </TableCell>

         <TableCell align="left" sx={{ textWrap: 'nowrap' }}>
            {cpf}
         </TableCell>

         {/* <TableCell align="left" sx={{ textWrap: 'nowrap' }}>
            {login}
         </TableCell> */}

         <TableCell align="left" sx={{ textWrap: 'nowrap' }}>
            <Label variant="ghost" color="info" sx={{ textTransform: 'uppercase' }}>
               {affiliateCode}
            </Label>
         </TableCell>

         <TableCell align="left" sx={{ textWrap: 'nowrap' }}>
            {fNumberReal(totalPaidAmount)}
         </TableCell>

         <TableCell align="left" sx={{ textWrap: 'nowrap' }}>
            {fNumberDecimals(totalPaidQuantity)}
         </TableCell>

         <TableCell align="left">
            <Label
               variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
               color={(!isActive && 'error') || 'success'}
               sx={{ textTransform: 'capitalize' }}
            >
               {isActive ? 'Ativo' : 'Bloqueado'}
            </Label>
         </TableCell>

         <TableCell align="right">
            <TableMoreMenu
               open={openMenu}
               onOpen={handleOpenMenu}
               onClose={handleCloseMenu}
               actions={
                  <>
                     <MenuItem
                        onClick={() => {
                           onEditRow();
                           handleCloseMenu();
                        }}
                     >
                        <Iconify icon={'eva:edit-fill'} />
                        Editar
                     </MenuItem>
                     {!user.affiliateCode && (
                        <MenuItem
                           onClick={() => {
                              handleGenerateAffiliateCode(row);
                           }}
                        >
                           <Iconify icon={'eva:credit-card-fill'} />
                           Gerar código de afiliado
                        </MenuItem>
                     )}
                  </>
               }
            />
         </TableCell>
      </TableRow>
   );
}
