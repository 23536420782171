import { useState } from 'react';

// ----------------------------------------------------------------------

export default function useTabs(defaultValues) {
   const [currentTab, setCurrentTab] = useState(defaultValues || '');

   return {
      currentTab,
      onChangeTab: (event, newValue) => {
         console.log('event', event);
         console.log('newValue', newValue);
         setCurrentTab(newValue);
      },
      setCurrentTab,
   };
}
