import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell } from '@mui/material';
//
import EmptyContent from '../EmptyContent';

// ----------------------------------------------------------------------

TableNoData.propTypes = {
   isNotFound: PropTypes.bool,
   title: PropTypes.string,
};

export default function TableNoData({ isNotFound, title = 'Sem Registros' }) {
   return (
      <TableRow>
         {isNotFound ? (
            <TableCell colSpan={12}>
               <EmptyContent
                  title={title}
                  sx={{
                     '& span.MuiBox-root': { height: 160 },
                  }}
               />
            </TableCell>
         ) : (
            <TableCell colSpan={12} sx={{ p: 0 }} />
         )}
      </TableRow>
   );
}
