
import React from 'react'

import { Dialog, DialogContent, CircularProgress, Typography } from '@mui/material'

export default function DialogDownloading() {
  return (
    <Dialog fullWidth open>
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <CircularProgress />
        <Typography>
          Estamos processando o arquivo... Por favor, aguarde!
        </Typography>
        <Typography variant={"body2"}>
          O download começará automaticamente.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
